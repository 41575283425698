import { render, staticRenderFns } from "./rankChange.vue?vue&type=template&id=1bab56d4&scoped=true&"
import script from "./rankChange.vue?vue&type=script&lang=ts&"
export * from "./rankChange.vue?vue&type=script&lang=ts&"
import style0 from "./rankChange.vue?vue&type=style&index=0&id=1bab56d4&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bab56d4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/mdagsh/tools/tools/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1bab56d4')) {
      api.createRecord('1bab56d4', component.options)
    } else {
      api.reload('1bab56d4', component.options)
    }
    module.hot.accept("./rankChange.vue?vue&type=template&id=1bab56d4&scoped=true&", function () {
      api.rerender('1bab56d4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user/rankChange.vue"
export default component.exports