var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: { placeholder: _vm.$t("user.vipLevel"), clearable: "" },
              on: { change: _vm.handleCommand },
              model: {
                value: _vm.listQuery.viptype,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "viptype", $$v)
                },
                expression: "listQuery.viptype"
              }
            },
            _vm._l(_vm.types, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.name, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          attrs: { data: _vm.list, border: "", fit: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("user.playerId"),
              prop: "date",
              align: "center",
              width: "120px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.uid))])]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }