var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("user.inputPlayerId") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.uid,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "uid", $$v)
              },
              expression: "listQuery.uid"
            }
          }),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": _vm.$t("statManager.to"),
              "start-placeholder": _vm.$t("statManager.startTime"),
              "end-placeholder": _vm.$t("statManager.endTime"),
              "picker-options": _vm.pickerOptions
            },
            model: {
              value: _vm.rangeTime,
              callback: function($$v) {
                _vm.rangeTime = $$v
              },
              expression: "rangeTime"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: _vm.$t("statManager.type"), clearable: "" },
              model: {
                value: _vm.listQuery.type,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type"
              }
            },
            _vm._l(_vm.typeOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.name, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: {
                placeholder: _vm.$t("statManager.optType"),
                clearable: ""
              },
              model: {
                value: _vm.listQuery.optType,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "optType", $$v)
                },
                expression: "listQuery.optType"
              }
            },
            _vm._l(_vm.optTypes, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: {
                  label: item.display_name + "(" + item.key + ")",
                  value: item.key
                }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("dashboard.type"),
              width: "120px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getTypeValue(scope.row.type)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("user.beforeCount"),
              width: "200px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.beforeCount))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("user.afterCount"),
              width: "200px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.afterCount))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("statManager.optType"),
              width: "180px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getOptTypeValue(scope.row.optType)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("agents.count"),
              width: "120px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.count))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("agents.chargeTime"),
              width: "180px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.date))])]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }